@media (max-height: 750px) {
    .slider_top {
        height: 600px;
    }
    .slider {
        &__title {
            font-size: 2.725em;
        }
        &__subtitle {
            margin: 40px 0 50px;
        }
    }
}
@media (max-width: 767px) {
    .header {
        padding-bottom: 10px;
        &__logo {
            float: none;
        }
        &__social {
            margin-top: 10px;
            text-align: right;
            margin-left: 10px;
        }
        &__phone {
            margin-top: 8px;
            float: left;
        }
        &__menu {
            position: static;
            margin-top: 40px;
        }
    }
    .main-menu {
        &__link {
            display: block;
            margin: 0;
            color: $black;
            text-align: center;
            &:hover {
                color: $black;
                &:after {
                    display: none;
                }
            }
            & + & {
                margin: 0;
            }
        }
    }
    .social-link+.social-link {
        margin-left: 5px;
    }
    .slider {
        &_top {
            height: 610px;
            & .container {
                &>.vertical-center {
                    position: static;
                    transform: translateY(0);
                }
            }
            & .swiper-pagination {
                bottom: 20px;
                left: 0;
                width: 100%;
                text-align: center;
                & .swiper-pagination-bullet {
                    margin: 0 10px;
                }
            }
        }
        &__item {
            padding: 20px 0;
        }
        &__title {
            font-size: 28px;
            padding-left: 0;
            margin: 0;
            width: 100%;
        }
        &__subtitle {
            padding-left: 0;
            width: 100%;
            font-size: 18px;
            line-height: 26px;
            margin: 20px 0 20px;
        }
        &__img {
            position: static;
            margin-bottom: 15px;
            width: 100%;
            padding-left: 0;
            height: 260px;
            text-align: center;
        }
        &__btn {
            padding-left: 0;
        }
    }
    .step {
        height: 740px;
        &__item {
            margin-top: 35px;
        }
    }
    .step-block {
        &__icon {
            &:after {
                display: none;
            }

        }
        &__text {
            margin-top: 15px;
            font-size: 14px;
        }
    }
    .tab-nav {
        &__item {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
            line-height: 16px;
            /* vertical-align: top; */
            text-align: center;
        }
    }
    .tab-link {
        display: block;
        font-size: 16px;
        font-weight: 600;
        padding: 12px 5px;
        border-radius: 5px;
    }
    .tab-title {
        font-size: 22px;
        float: none;
        text-align: center;
        margin-right: 0;
        margin-bottom: 10px;
    }
    .tab-content {
        background: #fff;
        padding: 35px 0;
    }
    .order {
        margin-top: -265px;
        &__header {
            margin-bottom: 20px;
        }
    }
    .form-control,
    .form-group {
        margin-bottom: 20px;
    }
    .sale-block {
        margin: 0 -15px;
        padding: 20px 0;
        &__text {
            font-size: 16px;
            margin-bottom: 15px;
            text-align: center;
        }
        &__btn {
            margin-bottom: 40px;
        }
        & .clock {
            width: 290px;
            margin: 0 auto;
        }
    }
    .clock {
        &:after {
            display: none;
        }
    }
    .count {
        &__number {
            font-size: 30px;
            line-height: 50px;
            padding: 0;
            width: 50px;
            height: 50px;
        }
        &__text {
            font-size: 12px;
            margin-bottom: 0;
        }
    }
    .prize {
        &__header {
            margin-bottom: 20px;
        }
        &__title {
            margin: 25px 0 25px;
            font-size: 22px;
        }
        &__subtitle {
            margin-bottom: 15px;
        }
        &__btn {
            margin-top: 30px;
            text-align: center;
        }

    }
    .about {
        &__title {
            font-size: 24px;
            font-weight: 600;
            margin: 30px 0;
        }
        &__item {
            margin-bottom: 20px;
            padding: 0 10px;
        }
        &__btn {
            margin: 10px 0 30px 0;
        }
    }
    .about-block {
        &__subtitle {
            font-size: 14px;
        }
    }
    .winner {
        padding: 30px 0;
        &__title {
            font-size: 24px;
            font-weight: 600;
            margin: 0 0 10px 0;
        }
        &__item {
            margin: 20px 0;
            & + & {
                margin-left: 0;
            }
        }
        &__btn {
            margin-top: 20px;
            & .btn {
                font-size: 16px;
            }
        }
    }
    .winner-block {
        display: block;
        width: 235px;
        height: 110px;
        margin-left: 50px;
        margin-right: 15px;
        &__photo {
            width: 110px;
            height: 110px;
            margin-left: -50px;
            overflow: hidden;
            background: #fff;
            padding: 10px;
            float: left;
        }
        &__name {
            margin-left: 60px;
        }
        &__info {
            margin-left: 60px;
        }
    }
    #map {
        height: 370px!important;
    }
    .contact {
        &__map {
            height: auto;
            &-form {
                margin: 20px auto;
                position: static;
                width: 100%;
                padding: 15px;
            }
        }
    }
    .footer {
        padding: 10px 0;
        position: relative;
        & .header__menu-btn {
            top: 25px;
        }
        &__copy {
            font-size: 14px;
            margin-top: 20px;
        }
        &__logo {
            float: none;
        }
        &__social {
            margin-top: 10px;
            text-align: right;
            margin-left: 10px;
        }
        &__phone {
            margin-top: 8px;
            float: left;
        }
        &__menu {
            position: static;
            margin-top: 50px;
            float: none;
            margin-left: 0;
        }
    }
}

@media (max-width: 479px) {

}

@media (min-width: 768px) and (max-width: 991px) {
    .slider {
        &_top {
            height: 550px;
        }
        &__title {
            font-size: 2em;
        }
        &__img {
            width: 55%;
            & img {
                max-height: 100%;
            }
        }
    }
    .step-block {
        &__icon:after {
            right: -25px;
        }
    }
    .tab-title {
         font-size: 20px;
         margin-right: 10px;
         margin-top: 8px;
     }
    .tab-link {
        font-size: 14px;
        padding: 12px 10px;
    }
    .order-form {
        & .btn {
            margin-top: 30px;
        }
    }
    .sale-block {
        &__text {
            font-size: 22px;
        }
        & .clock {
            &:after {
                right: -30px;
            }
        }
    }
    .count {
        &__number {
            font-size: 60px;
            line-height: 90px;
            width: 90px;
            height: 90px;
        }
        &__text {
            font-size: 20px;
        }
    }
    .about {
        &__title {
            margin: 60px;
        }
    }
    .prize {
        &__title {
            font-size: 22px;
            margin: 0 0 30px;
        }
        &__subtitle {
            font-size: 22px;
            font-weight: 400;
            margin-bottom: 20px;
        }
    }
    .winner {
        &__item {
            & + & {
              margin-left: 0;
            }
            &:nth-child(odd) {
                margin-left: 20px;
            }
        }
    }
    .winner-block {
        width: 280px;
    }
    .contact__map-form {
        left: 50px;
        width: 300px;
    }
    .footer {
        &__menu {
            float: left;
            width: 100%;
            display: block;
            margin-left: 0;
            margin-top: 15px;
            text-align: center;
        }
        &__copy {
            margin-top: 15px;
        }
    }

}

@media (min-width: 992px) and (max-width: 1200px) {
    .slider {
        &_top {
            height: 590px;
        }
        &__title {
            font-size: 2.5em;
        }
        &__img {
            width: 58%;
            & img {
                max-height: 100%;
            }
        }
    }
    .tab-link {
        padding: 12px 20px;
    }
    .contact__map-form {
        left: 50px;
    }
    .footer__menu {
        margin-left: 10px;
        & .main-menu__link+.main-menu__link {
            margin-left: 10px;
        }
    }
}
@media (min-width: 1201px) {

}
