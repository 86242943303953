$white: #fff;
$gray-slight: #f3f3f3;
$grey-light: #e0e0e0;
$grey: #cacaca;
$grey-slight2: #ededed;
$grey-dark: #808080;
$cyan: #00aae7;
$blue: #0087b8;
$darkblue: #004057;
$black: #000;
$green: #20adad;
$green-dark: #149790;
$purple: #7c69bd;
$red: #ff6269;
$yellow: #ffd200;
$yellow-dark: #5f4e00;
$orange: #d3802f;

$Roboto: 'Roboto', sans-serif;
$RobotoSlab: 'Roboto Slab', serif;
$RobotoCondensed: 'Roboto Condensed', sans-serif;


html {
    height: 100%;
}
body {
    width: 100%;
    margin: 0;
    background: $grey-slight2;
    font-family: $Roboto;
    font-size: 16px;
    line-height: 1.3;
    height: 100%;
    color: $black;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
img {
    max-width: 100%;
}

.main-container {
    overflow: hidden;
    min-height: 100%;
}
.section{
    width: 100%;
    &_bg {
        &-white {
            background: $white;
        }
        &-gray {
            background: $grey-slight2;
        }
        &-red {
            background: $red;
        }
    }
    &_shadow {
        &-bottom {
            box-shadow: inset 0 0 20px RGBA(0,0,0,.15);
        }
    }
	&-header {
		&__container {
			position: relative;
		}
		&__title {
			&_sidebarred {
				width: 74.475%;
				margin-right: 2.1%;
				float: left;
			}
			&_marginned {
				margin-top: 50px;
			}
			&_sidebar {
				width: 23.425%;
				font-size: 0.929em;
				position: absolute;
				bottom: 25px;
				right: 10px;
			}
		}
		&__subtitle {
			margin-top: 50px;
			margin-bottom: 25px;
			font-size: 2.143em;
		}
	}
}
.row,
.clearfix {
    @include cf();
}

.rel {
    position: relative;
}
.hidden {
    display: none !important;
}
.vertical-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}