h1, h2, h3, h4 {
    line-height: 1;
}

a {
    color: $black;
    text-decoration: none;
    transition: all 0.3s ease-out;
    &:hover {
        text-decoration: none;
    }
}
p {
    margin-bottom: 15px;
}
.rel {
    position: relative;
}
.btn {
    display: inline-block;
    border: 0;
    text-decoration: none;
    font-style: normal;
    min-width: 100px;
    height: 60px;
    padding: 0 25px;
    font-weight: 600;
    font-size: 20px;
    line-height: 55px;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    color: $yellow-dark;
    background: $yellow;
    border-radius: 5px;
    box-shadow: inset 0 -6px 0 rgba(2, 2, 2, 0.18);
    transition: all 0.2s ease-out;
    &:hover {
        color: $white;
        background-color: $red;
        border-radius: 5px;
        box-shadow: inset 0 -6px 0 rgba(2, 2, 2, 0.18);
    }
    &.disabled,
    &[disabled] {
        cursor: not-allowed;
        filter: alpha(opacity=50);
        opacity: .50;
    }
    &_border {
        background: none;
        color: $white;
        border: 1px solid $white;
        box-shadow: none;
        font-weight: 300;
        &:hover {
            border: 1px solid $yellow;
            color: #000;
            background: $yellow;
            border-radius: 5px;
            box-shadow: inset 0 -6px 0 rgba(2, 2, 2, 0.18);
        }
    }
    &_hover-green {
        &:hover {
            background-color: $green;
            border-radius: 5px;
            box-shadow: inset 0 -6px 0 rgba(2, 2, 2, 0.18);
        }
    }
    &_width {
        &-100 {
            width: 100%;
            padding: 0;
        }
    }
}

.input {
    height: 60px;
    line-height: 30px;
    padding: 15px 25px;
    font-size: 20px;
    min-width: 100px;
    color: $black;
    border: 1px solid rgba(24, 24, 24, 0.2);
    border-radius: 5px;
    transition : border 0.3s ease-out;
    box-shadow: none;
	@include placeholder {
		color: #959595;
	}
    &:focus {
        box-shadow: none;
        border: 1px solid rgba(24, 24, 24, 0.2);
    }
    &.error {
        border-color: $red;
    }
    &.success {
        border-color: $green;
    }
}

.textarea {
    padding: 8px 10px;
    font-size: 12px;
    border: 1px solid #d7d7d7;
    min-width: 100px;
    color: $darkblue;
}
.form-control {
    display: block;
    width: 100%;
    &_login {
        height: 31px;
        border: 1px solid #b7b7b7;
    }
    &_success {
        border-color: $darkblue;
    }
    &_error {
        border-color: #dc0a00;
    }
    &_width {
        &-100 {
            width: 100%;
        }
    }
}
.form-error {
    color: #dc0a00;
    font-size: 12px;
    margin-top: 8px;
}
.form-notice {
    color: #8f8f8f;
    font-size: 12px;
    margin-top: 8px;
}
.form-group {
    display: block;
}
.form-group + .form-group {
    margin-top: 18px;
}
.form-group-inline {
    display: inline-block;
    margin: 2px 10px 2px 0;
}

.box-shadow {
    &_bottom {
        box-shadow: 0 2px 0 RGBA(0,0,0,.2);
        margin-bottom: 2px;
    }
}

.text {
    &-center {
        text-align: center;
    }
    &-color {
        &_green {
            color: $green;
        }
        &_red {
            color: $red;
        }
    }
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 1px solid #cccccc;
}
.table>thead:first-child>tr:first-child>td, .table>thead:first-child>tr:first-child>th {
    border-top: 0;
}
.table {
    width: 100%;
    max-width: 100%;
    color: $darkblue;
    & tr {
        & td,
        & th {
            padding: 10px 18px;
            line-height: 1.42857143;
            vertical-align: top;
            border-bottom: 1px solid #ccc;
            position: relative;
            &:before {
                content: '';
                width: 1px;
                height: 80%;
                background: #ccc;
                position: absolute;
                left: 0;
                top: 10%;
            }
            &:first-child {
                &:before {
                    display: none;
                }
            }
        }
    }
}
.block {
    &-border {
        &_dashed {
            border: 1px dashed #ccc;
            border-radius: 2px;
        }
    }
}
.pull-left {
	float: left;
}
.pull-right {
	float: right;
}
.header {
    &__menu {
        &-btn {
            cursor: pointer;
            font-size: 23px;
            height: 30px;
            z-index: 100;
            color: $black;
            position: absolute;
            right: 15px;
            top: 21px;
            & span {
                -webkit-transition: -webkit-transform 200ms cubic-bezier(0.63, 0.62, 0.48, 1.64);
                -moz-transition: -moz-transform 200ms cubic-bezier(0.63, 0.62, 0.48, 1.64);
                -ms-transition: -ms-transform 200ms cubic-bezier(0.63, 0.62, 0.48, 1.64);
                -o-transition: -o-transform 200ms cubic-bezier(0.63, 0.62, 0.48, 1.64);
                transition: transform 200ms cubic-bezier(0.63, 0.62, 0.48, 1.64);
                background: $black;
                display: block;
                width: 30px;
                height: 3px;
            }
            & .top,
            & .meat {
                margin-bottom: 4px;
            }
            & .meat {
                -webkit-transition: opacity 300ms ease-in-out 0ms;
                -moz-transition: opacity 300ms ease-in-out 0ms;
                -ms-transition: opacity 300ms ease-in-out 0ms;
                -o-transition: opacity 300ms ease-in-out 0ms;
                transition: opacity 300ms ease-in-out 0ms;
            }
            &.close {
                opacity: 1;
                text-shadow: none;
            }
            &.close .top {
                -webkit-transform: rotateZ(45deg) translateX(5px) translateY(5px) !important;
                -moz-transform: rotateZ(45deg) translateX(5px) translateY(5px) !important;
                -ms-transform: rotateZ(45deg) translateX(5px) translateY(5px) !important;
                -o-transform: rotateZ(45deg) translateX(5px) translateY(5px) !important;
                transform: rotateZ(45deg) translateX(5px) translateY(5px) !important;
            }
            &.close .meat {
                opacity: 0;
            }
            &.close .bottom {
                -webkit-transform: rotateZ(-45deg) translateX(5px) translateY(-5px) !important;
                -moz-transform: rotateZ(-45deg) translateX(5px) translateY(-5px) !important;
                -ms-transform: rotateZ(-45deg) translateX(5px) translateY(-5px) !important;
                -o-transform: rotateZ(-45deg) translateX(5px) translateY(-5px) !important;
                transform: rotateZ(-45deg) translateX(5px) translateY(-5px) !important;
            }
        }
    }
}
.navbar-toggle {
    position: absolute;
    float: none;
    margin-right: 0;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
}
.popover {
    max-width: 285px;
    white-space: nowrap;
    color: $black;
    border: 1px solid $red;
    &-content {
        padding: 10px;
        font-size: 12px;
    }
    &.bottom>.arrow {
        border-bottom-color: $red;
    }
}