.header {
    position: relative;
    &__logo {
        float: left;
        padding: 4px 0;
    }
    &__phone {
        float: right;
        margin-top: 21px;
    }
    &__social {
        float: right;
        margin-top: 22px;
        margin-left: 30px;
    }
    &__menu {
        position: absolute;
        top: 85px;
        z-index: 10;
    }
}
.main-menu {
    &__link {
        color: $white;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
        padding: 5px 0;
        display: inline-block;
        .main-menu_black & {
            color: $black;
            &:after {
                background: $black;
            }
            &:hover {
                color: $black;
            }
        }
        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 0;
            height: 2px;
            background: $white;
            transition: all .3s ease-out;
        }
        &:hover {
            color: $white;
            &:after {
                height: 2px;
                width: 100%;
            }
        }
        & + & {
            margin-left: 35px;
        }
    }
}
.logo {
    &__link {
        @extend .icon-logo;
        display: block;
    }
    &__text {
        width: 100%;
        color: $grey-dark;
        font-size: 0.875em;
        font-weight: 400;
        margin: 5px 0 3px 0;
    }
}
.phone-block {
    color: black;
    font-size: 1.25em;
    text-transform: uppercase;
    font-weight: 300;
}
.social {
    &-link {
        display: inline-block;
        & + & {
            margin-left: 12px;
        }
    }
}
.slider {
    & img {
        max-height: 100%;
    }
    &__item {
        padding: 50px 0;
        & .container {
            height: 100%;
            position: relative;
        }
    }
    &__title {
        width: 520px;
        text-shadow: 0.5px 0.9px 2px rgba(24, 24, 24, 0.33);
        color: $white;
        font-family: $RobotoSlab;
        font-size: 3.125em;
        line-height: 1.25;
        font-weight: 700;
        text-transform: uppercase;
        padding-left: 50px;
    }
    &__subtitle {
        width: 350px;
        color: $white;
        font-size: 1.375em;
        line-height: 30px;
        font-weight: 400;
        margin: 50px 0 60px 0;
        padding-left: 50px;
    }
    &__img {
        position: absolute;
        right: 0;
        top:0;
        height: 100%;
        padding-left: 50px;
        width: 58%;
        text-align: center;
    }

    &__btn {
        padding-left: 50px;
    }
    &_top {
        height: 650px;
        & .swiper-pagination {
            bottom: 20px;
            left: 50px;
            text-align: left;
            & .swiper-pagination-bullet {
                margin-left: 20px;
            }
        }
    }
    &_bg-blue {
        background: $green url("../img/slider_bg-blue.jpg") no-repeat center top;
        background-size: cover;
    }
    &_bg-purple {
        background: $purple url("../img/slider_bg-purple.jpg") no-repeat center top;
        background-size: cover;
    }
    &_bg-orange {
        background: $orange url("../img/slider_bg-orange.jpg") no-repeat center top;
        background-size: cover;
    }
    &_bg-green {
        background: $green url("../img/slider_bg-green.jpg") no-repeat center top;
        background-size: cover;
    }
    &_bg-pink {
        background: #ca4069 url("../img/slider_bg-pink.jpg") no-repeat center top;
        background-size: cover;
    }
}
.slider-prize {
    padding: 0 20px;
    border: 1px solid rgba(24, 24, 24, 0.16);
    height: 496px;
    & .slider {
        height: 100%;
        padding: 40px 0;
    }
    & .swiper-pagination {
        bottom: 5px;
    }
}
.about {
    background: url("../img/about_bg.jpg") no-repeat center center;
    background-size: cover;
    &__title {
        color: $white;
        font-size: 30px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 80px;
        text-align: center;
    }
    &__btn {
        margin-bottom: 40px;
        text-align: center;
    }
    &__item {
        margin-bottom: 40px;
    }
}
.about-block {
    text-align: center;
    &__icon {

    }
    &__title {
        color: $white;
        font-size: 24px;
        font-weight: 600;
        margin: 20px 0 0 0;
    }
    &__subtitle {
        color: $white;
    }
}
.tab-title {
    color: black;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    float: left;
    margin-right: 20px;
    margin-top: 6px;
}
.tab-nav {
    list-style-type: none;
    margin: 0;
    &__item {
        display: inline-block;
    }
}
.tab-link {
    display: block;
    color: #828282;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 25px;
    text-transform: uppercase;
    border-radius: 5px 5px 0 0;
    text-decoration: none;
    &:hover,
    &:focus {
        color: #828282;
        text-decoration: none;
    }
    .active & {
        background: $white;
        color: $black;
        text-decoration: none;
        &:hover,
        &:focus {
            color: $black;
        }
    }
}
.tab-content {
    background: $white;
    padding: 65px 0;
}
.prize {
    & .btn {
        width: 260px;
    }
    & .nav-tabs {
        border-bottom: none;
        &>li {
            margin-bottom: 0
        }
    }
    &__title {
        color: black;
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0 0 50px 0;
    }
    &__subtitle {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 35px;
    }
    &__btn {
        margin-top: 35px;
    }
}
.step {
    height: 490px;
    &__item {
        margin-top: 70px;
        &:last-child {
            & .step-block__icon {
                &:after {
                    display: none;
                }
            }
        }
    }
}
.step-block {
    text-align: center;
    &__icon {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            right: -20px;
            top: 50%;
            margin-top: -18px;
            @include sprite($i-arrow-right);
        }
    }
    &__text {
        margin-top: 35px;
        color: $white;
    }
}
.order {
    margin-top: -160px;
    &__row {
        color: $grey-dark;
        font-size: 12px;
        line-height: 18px;
        & + & {
            margin-top: 35px;
        }
    }
    & .tab-title {
      color: $white;
    }
    & .tab-nav {
        &__item {
            & .tab-link {
                color: $white;
            }
            &.active  {
                & .tab-link {
                    color: $black;
                }
            }
        }
    }

    & .tab-content {
        padding: 40px 5px 20px;
        margin: 0 -20px;
        box-shadow: 3.5px 6.1px 13px rgba(24, 24, 24, 0.19);
    }
}
.order-rules {
    padding-left: 15px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: $grey;
    &__link {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        vertical-align: middle;
        position: relative;
        margin-right: 15px;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -20px;
            width: 0;
            height: 2px;
            background: black;
            transition: all 0.3s ease-out;
        }
        &:hover {
            color: $black;
            &:after {
                width: 100%;
            }
        }
    }
}
.order-form {

}
.sale-block {
	padding: 50px 0 50px 0;
    max-width: 970px;
    margin: 0 auto;
	color: #fff;
    &__text {
        color: $black;
        font-size: 30px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 23px;
    }
    &__btn {
        width: 270px;
        margin: 0 auto 50px auto;
        text-align: center;
    }
    &__people {
        text-align: center;
    }
    .clock {
        display: block;
        min-height: 100px;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 60px;
            @include sprite($i-sale-arrow);
        }

    }
}
.count {
    display: inline-block;
    margin-left: 28px;
    &__number {
        display: block;
        text-align: center;
        color: $red;
        font-family: $RobotoCondensed;
        font-size: 80px;
        font-weight: 300;
        line-height: 120px;
        padding: 0 10px;
        text-transform: uppercase;
        width: 120px;
        height: 120px;
        background-color: $white;
        box-shadow: 3.5px 6.1px 13px rgba(24, 24, 24, 0.19);
        position: relative;
        &:after {
            content: '';
            @include sprite($i-clock-dotted);
            position: absolute;
            right: -17px;
            top:50%;
            margin-top: -14px;
        }
    }
    &__text {
        display: block;
        margin-top: 13px;
        color: $black;
        font-size: 22px;
        font-weight: 400;
        text-align: center;
    }
    &_second {
        & .count__number {
            &:after {
                display: none;
            }
        }
    }
    &_day {
        margin-left: 0;
    }
    &_people {
        margin: 0;
        & .count__number {
            padding: 0 20px;
            min-width: 120px;
            width: auto;
            &:after {
                display: none;
            }
        }
    }
}
.winner {
    text-align: center;
    padding: 60px 0;
    &__title {
        font-size: 30px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    &__item {
        display: inline-block;
        vertical-align: top;
        margin: 25px 0;
        & + & {
            margin-left: 20px;
        }
    }
    &__btn {
        margin-top: 40px;
    }
}
.winner-block {
    display: inline-block;
    background-color: $white;
    box-shadow: 3.5px 6.1px 13px rgba(24, 24, 24, 0.19);
    width: 300px;
    height: 124px;
    position: relative;
    text-align: left;
    margin-left: 62px;
    &__photo {
        width: 124px;
        height: 124px;
        border-radius: 50%;
        margin-left: -62px;
        overflow: hidden;
        background: $white;
        padding: 10px;
        float: left;
    }
    &__info {
        margin-left: 75px;
    }
    &__name {
        color: $black;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        text-transform: uppercase;
        margin-left: 75px;
        margin-right: 10px;
    }
    &__info {
        color: black;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-left: 75px;
        margin-right: 10px;
    }
    &__icon {
        position: absolute;
        top:-15px;
        right: -15px;
        width: 46px;
        height: 46px;
        border-radius: 50%;
        background: $red;
        text-align: center;
        & .icon-play {
            margin-left: 5px;
            margin-bottom: 2px;
        }
        &:hover {
            background: $green;
        }
    }
}
#map {
    & .leaflet-popup-content-wrapper {
       background: $green;
    }
    & .leaflet-map-pane .leaflet-popup-tip-container_svg {
        fill: $green;
    }
    & .leaflet-container a.leaflet-popup-close-button {
        color: #fff;
    }
}
.contact {
    position: relative;
    &__map {
        position: relative;
        height: 670px;
        &-form {
            position: absolute;
            top: 53px;
            width: 330px;
            padding: 28px;
            color: $black;
            font-weight: 100;
            background: #FFFFFF;
            line-height: 24px;
            box-shadow: 3.5px 6.1px 13px rgba(24, 24, 24, 0.19);
            &-title {
                font-size: 22px;
                font-weight: 600;
                text-transform: uppercase;
            }
            &-subtitle {
                margin: 5px 0 30px 0;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
    }
}
.footer {
    padding: 10px;
    &__row {
        & + & {
            margin-top: 10px;
        }
    }
    &__copy {
        font-size: 14px;
        margin-top: 25px;
        color: #aeaeae;
        font-weight: 100;
    }
    &__thekilo {
        text-align: right;
    }
    &__logo {
        float: left;
    }
    &__phone {
        float: right;
        margin-top: 11px;
    }
    &__social {
        float: right;
        margin-top: 12px;
        margin-left: 30px;
    }
    &__menu {
        float: left;
        margin-top: 12px;
        margin-left: 30px;
    }
}