@import 'sprite';

.icon {
    display: inline-block;
    vertical-align: middle;
}
.icon-fb {
    @include sprite($i-fb);
}
.icon-vk {
    @include sprite($i-vk);
}
.icon-tw {
    @include sprite($i-tw);
}
.icon-tw_blue {
    @include sprite($i-tw_blue);
}
.icon-logo {
    @include sprite($i-logo);
}
.icon-delivery {
    @include sprite($i-delivery);
}
.icon-ok {
    @include sprite($i-ok);
}
.icon-reward {
    @include sprite($i-reward);
}
.icon-crown {
    @include sprite($i-crown);
}
.icon-earth {
    @include sprite($i-earth);
}
.icon-smiles {
    @include sprite($i-smiles);
}
.icon-smile {
    @include sprite($i-smile);
}
.icon-doc {
    @include sprite($i-doc);
}
.icon-thekilo {
    @include sprite($i-thekilo);
}
.icon-step_1 {
    @include sprite($i-step_1);
}
.icon-step_2 {
    @include sprite($i-step_2);
}
.icon-step_3 {
    @include sprite($i-step_3);
}
.icon-step_4 {
    @include sprite($i-step_4);
}
.icon-play {
    @include sprite($i-play);
}
.icon-message {
    @include sprite($i-message);
}