/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$i-arrow-right-name: 'i-arrow-right';
$i-arrow-right-x: 499px;
$i-arrow-right-y: 220px;
$i-arrow-right-offset-x: -499px;
$i-arrow-right-offset-y: -220px;
$i-arrow-right-width: 20px;
$i-arrow-right-height: 37px;
$i-arrow-right-total-width: 562px;
$i-arrow-right-total-height: 419px;
$i-arrow-right-image: '../img/sprite.png';
$i-arrow-right: (499px, 220px, -499px, -220px, 20px, 37px, 562px, 419px, '../img/sprite.png', 'i-arrow-right', );
$i-clock-dotted-name: 'i-clock-dotted';
$i-clock-dotted-x: 499px;
$i-clock-dotted-y: 315px;
$i-clock-dotted-offset-x: -499px;
$i-clock-dotted-offset-y: -315px;
$i-clock-dotted-width: 6px;
$i-clock-dotted-height: 27px;
$i-clock-dotted-total-width: 562px;
$i-clock-dotted-total-height: 419px;
$i-clock-dotted-image: '../img/sprite.png';
$i-clock-dotted: (499px, 315px, -499px, -315px, 6px, 27px, 562px, 419px, '../img/sprite.png', 'i-clock-dotted', );
$i-crown-name: 'i-crown';
$i-crown-x: 0px;
$i-crown-y: 257px;
$i-crown-offset-x: 0px;
$i-crown-offset-y: -257px;
$i-crown-width: 85px;
$i-crown-height: 78px;
$i-crown-total-width: 562px;
$i-crown-total-height: 419px;
$i-crown-image: '../img/sprite.png';
$i-crown: (0px, 257px, 0px, -257px, 85px, 78px, 562px, 419px, '../img/sprite.png', 'i-crown', );
$i-delivery-name: 'i-delivery';
$i-delivery-x: 275px;
$i-delivery-y: 152px;
$i-delivery-offset-x: -275px;
$i-delivery-offset-y: -152px;
$i-delivery-width: 106px;
$i-delivery-height: 75px;
$i-delivery-total-width: 562px;
$i-delivery-total-height: 419px;
$i-delivery-image: '../img/sprite.png';
$i-delivery: (275px, 152px, -275px, -152px, 106px, 75px, 562px, 419px, '../img/sprite.png', 'i-delivery', );
$i-doc-name: 'i-doc';
$i-doc-x: 499px;
$i-doc-y: 0px;
$i-doc-offset-x: -499px;
$i-doc-offset-y: 0px;
$i-doc-width: 63px;
$i-doc-height: 87px;
$i-doc-total-width: 562px;
$i-doc-total-height: 419px;
$i-doc-image: '../img/sprite.png';
$i-doc: (499px, 0px, -499px, 0px, 63px, 87px, 562px, 419px, '../img/sprite.png', 'i-doc', );
$i-earth-name: 'i-earth';
$i-earth-x: 0px;
$i-earth-y: 339px;
$i-earth-offset-x: 0px;
$i-earth-offset-y: -339px;
$i-earth-width: 80px;
$i-earth-height: 80px;
$i-earth-total-width: 562px;
$i-earth-total-height: 419px;
$i-earth-image: '../img/sprite.png';
$i-earth: (0px, 339px, 0px, -339px, 80px, 80px, 562px, 419px, '../img/sprite.png', 'i-earth', );
$i-fb-name: 'i-fb';
$i-fb-x: 523px;
$i-fb-y: 220px;
$i-fb-offset-x: -523px;
$i-fb-offset-y: -220px;
$i-fb-width: 24px;
$i-fb-height: 23px;
$i-fb-total-width: 562px;
$i-fb-total-height: 419px;
$i-fb-image: '../img/sprite.png';
$i-fb: (523px, 220px, -523px, -220px, 24px, 23px, 562px, 419px, '../img/sprite.png', 'i-fb', );
$i-logo-name: 'i-logo';
$i-logo-x: 275px;
$i-logo-y: 0px;
$i-logo-offset-x: -275px;
$i-logo-offset-y: 0px;
$i-logo-width: 220px;
$i-logo-height: 39px;
$i-logo-total-width: 562px;
$i-logo-total-height: 419px;
$i-logo-image: '../img/sprite.png';
$i-logo: (275px, 0px, -275px, 0px, 220px, 39px, 562px, 419px, '../img/sprite.png', 'i-logo', );
$i-message-name: 'i-message';
$i-message-x: 527px;
$i-message-y: 288px;
$i-message-offset-x: -527px;
$i-message-offset-y: -288px;
$i-message-width: 22px;
$i-message-height: 21px;
$i-message-total-width: 562px;
$i-message-total-height: 419px;
$i-message-image: '../img/sprite.png';
$i-message: (527px, 288px, -527px, -288px, 22px, 21px, 562px, 419px, '../img/sprite.png', 'i-message', );
$i-ok-name: 'i-ok';
$i-ok-x: 89px;
$i-ok-y: 257px;
$i-ok-offset-x: -89px;
$i-ok-offset-y: -257px;
$i-ok-width: 73px;
$i-ok-height: 76px;
$i-ok-total-width: 562px;
$i-ok-total-height: 419px;
$i-ok-image: '../img/sprite.png';
$i-ok: (89px, 257px, -89px, -257px, 73px, 76px, 562px, 419px, '../img/sprite.png', 'i-ok', );
$i-play-name: 'i-play';
$i-play-x: 544px;
$i-play-y: 175px;
$i-play-offset-x: -544px;
$i-play-offset-y: -175px;
$i-play-width: 18px;
$i-play-height: 20px;
$i-play-total-width: 562px;
$i-play-total-height: 419px;
$i-play-image: '../img/sprite.png';
$i-play: (544px, 175px, -544px, -175px, 18px, 20px, 562px, 419px, '../img/sprite.png', 'i-play', );
$i-reward-name: 'i-reward';
$i-reward-x: 499px;
$i-reward-y: 91px;
$i-reward-offset-x: -499px;
$i-reward-offset-y: -91px;
$i-reward-width: 54px;
$i-reward-height: 80px;
$i-reward-total-width: 562px;
$i-reward-total-height: 419px;
$i-reward-image: '../img/sprite.png';
$i-reward: (499px, 91px, -499px, -91px, 54px, 80px, 562px, 419px, '../img/sprite.png', 'i-reward', );
$i-sale-arrow-name: 'i-sale-arrow';
$i-sale-arrow-x: 0px;
$i-sale-arrow-y: 107px;
$i-sale-arrow-offset-x: 0px;
$i-sale-arrow-offset-y: -107px;
$i-sale-arrow-width: 82px;
$i-sale-arrow-height: 146px;
$i-sale-arrow-total-width: 562px;
$i-sale-arrow-total-height: 419px;
$i-sale-arrow-image: '../img/sprite.png';
$i-sale-arrow: (0px, 107px, 0px, -107px, 82px, 146px, 562px, 419px, '../img/sprite.png', 'i-sale-arrow', );
$i-smile-name: 'i-smile';
$i-smile-x: 192px;
$i-smile-y: 107px;
$i-smile-offset-x: -192px;
$i-smile-offset-y: -107px;
$i-smile-width: 79px;
$i-smile-height: 79px;
$i-smile-total-width: 562px;
$i-smile-total-height: 419px;
$i-smile-image: '../img/sprite.png';
$i-smile: (192px, 107px, -192px, -107px, 79px, 79px, 562px, 419px, '../img/sprite.png', 'i-smile', );
$i-smiles-name: 'i-smiles';
$i-smiles-x: 363px;
$i-smiles-y: 43px;
$i-smiles-offset-x: -363px;
$i-smiles-offset-y: -43px;
$i-smiles-width: 104px;
$i-smiles-height: 79px;
$i-smiles-total-width: 562px;
$i-smiles-total-height: 419px;
$i-smiles-image: '../img/sprite.png';
$i-smiles: (363px, 43px, -363px, -43px, 104px, 79px, 562px, 419px, '../img/sprite.png', 'i-smiles', );
$i-step-1-name: 'i-step_1';
$i-step-1-x: 0px;
$i-step-1-y: 0px;
$i-step-1-offset-x: 0px;
$i-step-1-offset-y: 0px;
$i-step-1-width: 138px;
$i-step-1-height: 103px;
$i-step-1-total-width: 562px;
$i-step-1-total-height: 419px;
$i-step-1-image: '../img/sprite.png';
$i-step-1: (0px, 0px, 0px, 0px, 138px, 103px, 562px, 419px, '../img/sprite.png', 'i-step_1', );
$i-step-2-name: 'i-step_2';
$i-step-2-x: 86px;
$i-step-2-y: 107px;
$i-step-2-offset-x: -86px;
$i-step-2-offset-y: -107px;
$i-step-2-width: 102px;
$i-step-2-height: 112px;
$i-step-2-total-width: 562px;
$i-step-2-total-height: 419px;
$i-step-2-image: '../img/sprite.png';
$i-step-2: (86px, 107px, -86px, -107px, 102px, 112px, 562px, 419px, '../img/sprite.png', 'i-step_2', );
$i-step-3-name: 'i-step_3';
$i-step-3-x: 142px;
$i-step-3-y: 0px;
$i-step-3-offset-x: -142px;
$i-step-3-offset-y: 0px;
$i-step-3-width: 129px;
$i-step-3-height: 95px;
$i-step-3-total-width: 562px;
$i-step-3-total-height: 419px;
$i-step-3-image: '../img/sprite.png';
$i-step-3: (142px, 0px, -142px, 0px, 129px, 95px, 562px, 419px, '../img/sprite.png', 'i-step_3', );
$i-step-4-name: 'i-step_4';
$i-step-4-x: 275px;
$i-step-4-y: 43px;
$i-step-4-offset-x: -275px;
$i-step-4-offset-y: -43px;
$i-step-4-width: 84px;
$i-step-4-height: 105px;
$i-step-4-total-width: 562px;
$i-step-4-total-height: 419px;
$i-step-4-image: '../img/sprite.png';
$i-step-4: (275px, 43px, -275px, -43px, 84px, 105px, 562px, 419px, '../img/sprite.png', 'i-step_4', );
$i-thekilo-name: 'i-thekilo';
$i-thekilo-x: 499px;
$i-thekilo-y: 175px;
$i-thekilo-offset-x: -499px;
$i-thekilo-offset-y: -175px;
$i-thekilo-width: 41px;
$i-thekilo-height: 41px;
$i-thekilo-total-width: 562px;
$i-thekilo-total-height: 419px;
$i-thekilo-image: '../img/sprite.png';
$i-thekilo: (499px, 175px, -499px, -175px, 41px, 41px, 562px, 419px, '../img/sprite.png', 'i-thekilo', );
$i-tw-name: 'i-tw';
$i-tw-x: 499px;
$i-tw-y: 261px;
$i-tw-offset-x: -499px;
$i-tw-offset-y: -261px;
$i-tw-width: 24px;
$i-tw-height: 23px;
$i-tw-total-width: 562px;
$i-tw-total-height: 419px;
$i-tw-image: '../img/sprite.png';
$i-tw: (499px, 261px, -499px, -261px, 24px, 23px, 562px, 419px, '../img/sprite.png', 'i-tw', );
$i-tw-blue-name: 'i-tw_blue';
$i-tw-blue-x: 527px;
$i-tw-blue-y: 261px;
$i-tw-blue-offset-x: -527px;
$i-tw-blue-offset-y: -261px;
$i-tw-blue-width: 24px;
$i-tw-blue-height: 23px;
$i-tw-blue-total-width: 562px;
$i-tw-blue-total-height: 419px;
$i-tw-blue-image: '../img/sprite.png';
$i-tw-blue: (527px, 261px, -527px, -261px, 24px, 23px, 562px, 419px, '../img/sprite.png', 'i-tw_blue', );
$i-vk-name: 'i-vk';
$i-vk-x: 499px;
$i-vk-y: 288px;
$i-vk-offset-x: -499px;
$i-vk-offset-y: -288px;
$i-vk-width: 24px;
$i-vk-height: 23px;
$i-vk-total-width: 562px;
$i-vk-total-height: 419px;
$i-vk-image: '../img/sprite.png';
$i-vk: (499px, 288px, -499px, -288px, 24px, 23px, 562px, 419px, '../img/sprite.png', 'i-vk', );
$spritesheet-width: 562px;
$spritesheet-height: 419px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($i-arrow-right, $i-clock-dotted, $i-crown, $i-delivery, $i-doc, $i-earth, $i-fb, $i-logo, $i-message, $i-ok, $i-play, $i-reward, $i-sale-arrow, $i-smile, $i-smiles, $i-step-1, $i-step-2, $i-step-3, $i-step-4, $i-thekilo, $i-tw, $i-tw-blue, $i-vk, );
$spritesheet: (562px, 419px, '../img/sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
